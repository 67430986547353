import { useEffect, useState } from "react";
import Image from "next/image";
import { Tab } from "@headlessui/react";
import clsx from "clsx";

import { Container } from "@main-zone/components/Container";
import backgroundImage from "@public/images/background-features.jpg";
import screenshotExpenses from "@public/images/screenshots/screenshot-faktury.png";
import screenshotPayroll from "@public/images/screenshots/screenshot-vynosy.png";
import screenshotReporting from "@public/images/screenshots/screenshot-dashboard.png";
import screenshotVatReturns from "@public/images/screenshots/screenshot-dane.png";

const features = [
    {
        title: "Reporting",
        description: "Neustálý přehled o\xA0vašem hospodaření, úhradách faktur, splatnosti dokladů a\xA0bance.",
        image: screenshotReporting,
    },
    {
        title: "Mzdová agenda",
        description:
            "Všechny potřebné informace o\xA0mzdách a\xA0odvodech jsou automaticky shromažďovány a\xA0zpracovávány v\xA0rámci online účetnictví.",
        image: screenshotPayroll,
    },
    {
        title: "Fakturace",
        description:
            "Ušetříme Vám čas a\xA0námahu, u\xA0nás vložíte či vygenerujete doklad na\xA0pár kliknutí,\n" +
            "klidně z\xA0mobilu.",
        image: screenshotExpenses,
    },
    {
        title: "DPH",
        description:
            "Můžete se\xA0na nás spolehnout se\xA0zpracováním a\xA0přípravou všech podkladů DPH. Plná podpora při plnění daňových povinností.",
        image: screenshotVatReturns,
    },
];

export function PrimaryFeatures() {
    const [tabOrientation, setTabOrientation] = useState("horizontal");

    useEffect(() => {
        const lgMediaQuery = window.matchMedia("(min-width: 1024px)");

        function onMediaQueryChange(ev: any) {
            setTabOrientation(ev.matches ? "vertical" : "horizontal");
        }

        onMediaQueryChange(lgMediaQuery);
        lgMediaQuery.addEventListener("change", onMediaQueryChange);

        return () => {
            lgMediaQuery.removeEventListener("change", onMediaQueryChange);
        };
    }, []);

    return (
        <section
            id="features"
            aria-label="Features for running your books"
            className="relative overflow-hidden bg-blue-600 pt-20 pb-28 sm:py-32"
        >
            <Image
                className="absolute top-1/2 left-1/2 max-w-none translate-x-[-44%] translate-y-[-42%]"
                src={backgroundImage}
                alt=""
                width={2245}
                height={1636}
                unoptimized
            />
            <Container className="relative">
                <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
                    <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">
                        Komplexní vedení účetnictví, personalistiky a&nbsp;mezd.
                    </h2>
                    <p className="mt-6 text-lg tracking-tight text-blue-100">
                        Postaráme se&nbsp;o&nbsp;vaše účetnictví, co jde, tak zpracujeme automatizovaně a&nbsp;online.
                        Šetříme tak nejenom vaše náklady, ale i&nbsp;přírodu.
                    </p>
                </div>
                <Tab.Group
                    as="div"
                    className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0"
                    vertical={tabOrientation === "vertical"}
                >
                    {({ selectedIndex }) => (
                        <>
                            <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                                <Tab.List className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
                                    {features.map((feature, featureIndex) => (
                                        <div
                                            key={feature.title}
                                            className={clsx(
                                                "group relative rounded-full py-1 px-4 lg:rounded-r-none lg:rounded-l-xl lg:p-6",
                                                selectedIndex === featureIndex
                                                    ? "bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10"
                                                    : "hover:bg-white/10 lg:hover:bg-white/5",
                                            )}
                                        >
                                            <h3>
                                                <Tab
                                                    className={clsx(
                                                        "font-display text-lg [&:not(:focus-visible)]:focus:outline-none",
                                                        selectedIndex === featureIndex
                                                            ? "text-blue-600 lg:text-white"
                                                            : "text-blue-100 hover:text-white lg:text-white",
                                                    )}
                                                >
                                                    <span className="absolute inset-0 rounded-full lg:rounded-r-none lg:rounded-l-xl" />
                                                    {feature.title}
                                                </Tab>
                                            </h3>
                                            <p
                                                className={clsx(
                                                    "mt-2 hidden text-sm lg:block",
                                                    selectedIndex === featureIndex
                                                        ? "text-white"
                                                        : "text-blue-100 group-hover:text-white",
                                                )}
                                            >
                                                {feature.description}
                                            </p>
                                        </div>
                                    ))}
                                </Tab.List>
                            </div>
                            <Tab.Panels className="lg:col-span-7">
                                {features.map((feature) => (
                                    <Tab.Panel key={feature.title} unmount={false}>
                                        <div className="relative sm:px-6 lg:hidden">
                                            <div className="absolute -inset-x-4 top-[-6.5rem] bottom-[-4.25rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl" />
                                            <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
                                                {feature.description}
                                            </p>
                                        </div>
                                        <div className="mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
                                            <Image
                                                className="w-full"
                                                src={feature.image}
                                                alt=""
                                                priority
                                                sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                                            />
                                        </div>
                                    </Tab.Panel>
                                ))}
                            </Tab.Panels>
                        </>
                    )}
                </Tab.Group>
            </Container>
        </section>
    );
}
