import { Container } from "@main-zone/components/Container";
import { Logo } from "@main-zone/components/Logo";

export function Footer() {
    return (
        <footer className="bg-slate-50">
            <Container>
                <div className="py-16"></div>
                <div className="flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between">
                    <p className="mt-6 flex items-center text-sm text-slate-500 sm:mt-0">
                        <Logo className="mr-6 h-16 w-auto" />
                        <span>
                            Copyright &copy; {new Date().getFullYear()} Účtio.cz s.r.o. - Web a zákaznickou zónu
                            vytvořilo <a href="https://www.uxf.cz">UX Fans s.r.o.</a>
                        </span>
                    </p>
                </div>
            </Container>
        </footer>
    );
}
