import Head from "next/head";

import { CallToAction } from "@main-zone/components/CallToAction";
import { Faqs } from "@main-zone/components/Faqs";
import { Footer } from "@main-zone/components/Footer";
import { Header } from "@main-zone/components/Header";
import { Hero } from "@main-zone/components/Hero";
import { Pricing } from "@main-zone/components/Pricing";
import { PrimaryFeatures } from "@main-zone/components/PrimaryFeatures";
import { SecondaryFeatures } from "@main-zone/components/SecondaryFeatures";
import { Testimonials } from "@main-zone/components/Testimonials";
import { Contacts } from "@main-zone/components/Contacts";

export default function Home() {
    return (
        <>
            <Head>
                <title>Účetnictví pro malé a střední podnikatele | Účtio.cz</title>
                <meta
                    name="description"
                    content="Převeďte vaše účetnictví do online světa. Zjednodušíme vaše procesy, nastavíme automatizaci. Mzdová agenda, fakturace, DPH i reporting. To je Účtio."
                />
            </Head>
            <Header />
            <main>
                <Hero />
                <PrimaryFeatures />
                <SecondaryFeatures />
                <CallToAction />
                <Testimonials />
                <Pricing />
                <Faqs />
                <Contacts />
            </main>
            <Footer />
        </>
    );
}
