import Image from "next/image";

import { Button } from "@main-zone/components/Button";
import { Container } from "@main-zone/components/Container";
import backgroundImage from "@public/images/background-call-to-action.jpg";

export function CallToAction() {
    return (
        <section id="get-started-today" className="relative overflow-hidden bg-blue-600 py-32">
            <Image
                className="absolute top-1/2 left-1/2 max-w-none -translate-x-1/2 -translate-y-1/2"
                src={backgroundImage}
                alt=""
                width={2347}
                height={1244}
                unoptimized
            />
            <Container className="relative">
                <div className="mx-auto max-w-lg text-center">
                    <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
                        Začněte kdykoliv, snadno a&nbsp;rychle.
                    </h2>
                    <p className="mt-4 text-lg tracking-tight text-white">
                        Pusťte účetnictví z&nbsp;hlavy. Vyplňte jednoduchý formulář a&nbsp;my se&nbsp;Vám ozveme
                        s&nbsp;konkrétní cenovou nabídkou.
                    </p>
                    <Button href="/poptavka" color="white" className="mt-10">
                        Pošlete mi nabídku
                    </Button>
                </div>
            </Container>
        </section>
    );
}
