import Image from "next/image";

import { Container } from "@main-zone/components/Container";
import backgroundImage from "@public/images/background-faqs.jpg";
import photoCermak from "@public/images/people/adam-cermak.jpg";
import photoTryznova from "@public/images/people/veronika-tryznova.jpg";
import photoValousek from "@public/images/people/tomas-valousek.jpg";
import photoBatman from "@public/images/people/batman.jpg";

const people = [
    {
        name: "Ing. Veronika Tryznová",
        role: "jednatelka",
        phone: "732267499",
        email: "tryznova@uctio.cz",
        imageUrl: photoTryznova,
    },
    {
        name: "Tomáš Valoušek",
        role: "jednatel",
        phone: "724187025",
        email: "valousek@uctio.cz",
        imageUrl: photoValousek,
    },
    {
        name: "Adam Čermák",
        role: "obchod",
        phone: "775993234",
        email: "cermak@uctio.cz",
        imageUrl: photoCermak,
    },
    {
        name: "Petr Krejcar",
        role: "obchod",
        phone: "773135787",
        email: "p.krejcar@gmail.com",
        imageUrl: photoBatman,
    },
];

export function Contacts() {
    return (
        <section
            id="contacts"
            aria-labelledby="faq-title"
            className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
        >
            <Image
                className="absolute top-0 left-1/2 max-w-none translate-x-[-30%] -translate-y-1/4"
                src={backgroundImage}
                alt=""
                width={1558}
                height={946}
                unoptimized
            />
            <Container className="relative">
                <div className="">
                    <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
                        <div className="max-w-2xl">
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                Účtio.cz s.r.o.
                            </h2>
                            <p className="mt-6 text-lg leading-8 text-gray-600">IČ: 17927293</p>
                            <p className="mt-3 text-lg leading-8 text-gray-600">DIČ: CZ17927293</p>
                            <p className="mt-3 text-lg leading-8 text-gray-600">Sovova 584 / 2, Liberec 14, 46014</p>
                        </div>
                        <ul className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
                            {people.map((person) => (
                                <li key={person.name}>
                                    <div className="flex items-center gap-x-6">
                                        <Image className="h-20 w-20 rounded-full" src={person.imageUrl} alt="" />
                                        <div>
                                            <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                                                {person.name}
                                            </h3>
                                            <p className="text-sm font-semibold leading-6 text-indigo-600">
                                                {person.role}
                                            </p>
                                            <p className="text-sm leading-6 text-gray-600">
                                                tel.: <a href={`tel:${person.phone}`}>{person.phone}</a>
                                            </p>
                                            <p className="text-sm  leading-6 text-gray-600">
                                                e-mail: <a href={`mailto:${person.email}`}>{person.email}</a>
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </Container>
        </section>
    );
}
