import Link from "next/link";
import { ReactNode } from "react";

interface NavLinkProps {
    href: string;
    children: ReactNode;
}
export function NavLink(props: NavLinkProps) {
    return (
        <Link
            href={props.href}
            className="inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
        >
            {props.children}
        </Link>
    );
}
