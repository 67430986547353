import { Container } from "@main-zone/components/Container";

const faqs = [
    [
        {
            question: "Účtujete i OSVČ?",
            answer: "Ano, postaráme se i o OSVČ, připravíme podklady pro daň z příjmu FO, sociální pojištění a zdravotní pojištění.",
        },
        {
            question: "Účtujete i spolky a SVJ?",
            answer: "Ano, vedene účetnictví zapsaných spolků a společenství vlastníků SVJ.",
        },
        {
            question: "Postaráte se o datovou schránku?",
            answer: "Ano, postaráme se o datovou schránku, nic důležitého vám neuteče.",
        },
    ],
    [
        {
            question: "Zastoupíte moji firmu i na úřadech?",
            answer: "Ano, naše klienty zastupujeme i na finančním úřadu, sociální a zdravotní pojišťovně.",
        },
        {
            question: "Moji zaměstnanci mají exekuce, porádíte si s tím?",
            answer: "Ano porádíme. V případě vyšší pracnosti účtujeme za jednání s exekutory poplatek.",
        },
        {
            question: "Budu mít přehled o cashflow?",
            answer: "Ano, díky automatizovanému zpracování bankovních výpisů a pravidelnému zaúčtovávání vašich dokladů budete mít přehled o cashflow.",
        },
    ],
    [
        {
            question: "Jak často generujete reporty?",
            answer: "Reporty se generují online a zobrazují aktuální stav vašeho účetnictví.",
        },
        {
            question: "Mohu se ucházet o práci v Účtio.cz?",
            answer: (
                <>
                    Ano, stále hledáme nové talenty i zkušené učetní. Napište nám na{" "}
                    <a href="mailto:tryznova@uxf.cz">tryznova@uxf.cz</a>
                </>
            ),
        },
        {
            question: "Ztratil jsem heslo, mohu ho obnovit?",
            answer: (
                <>
                    Ano, heslo můžete snadno obnovit <a href="https://app.uctio.cz/forgotten-password">zde</a>.
                </>
            ),
        },
    ],
];

export function Faqs() {
    return (
        <section id="faq" aria-labelledby="faq-title" className="relative overflow-hidden bg-slate-50 py-20 sm:py-32">
            <Container className="relative">
                <div className="mx-auto max-w-2xl lg:mx-0">
                    <h2 id="faq-title" className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
                        Často kladené otázky
                    </h2>
                    <p className="mt-4 text-lg tracking-tight text-slate-700">
                        Nenašli jste co jste hledali? Kontaktujte naši e-mailovou podporu.
                    </p>
                </div>
                <ul className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3">
                    {faqs.map((column, columnIndex) => (
                        <li key={columnIndex}>
                            <ul className="flex flex-col gap-y-8">
                                {column.map((faq, faqIndex) => (
                                    <li key={faqIndex}>
                                        <h3 className="font-display text-lg leading-7 text-slate-900">
                                            {faq.question}
                                        </h3>
                                        <p className="mt-4 text-sm text-slate-700">{faq.answer}</p>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            </Container>
        </section>
    );
}
