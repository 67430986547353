import { Fragment, ReactNode } from "react";
import Link from "next/link";
import { Popover, Transition } from "@headlessui/react";
import clsx from "clsx";

import { Container } from "@main-zone/components/Container";
import { Logo } from "@main-zone/components/Logo";
import { NavLink } from "@main-zone/components/NavLink";

interface MobileNavLinkProps {
    href: string;
    children: ReactNode;
}

function MobileNavLink(props: MobileNavLinkProps) {
    return (
        <Popover.Button as={Link} href={props.href} className="block w-full p-2">
            {props.children}
        </Popover.Button>
    );
}

interface MobileNavIconProps {
    open: boolean;
}
function MobileNavIcon(props: MobileNavIconProps) {
    return (
        <svg
            aria-hidden="true"
            className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
            fill="none"
            strokeWidth={2}
            strokeLinecap="round"
        >
            <path
                d="M0 1H14M0 7H14M0 13H14"
                className={clsx("origin-center transition", props.open && "scale-90 opacity-0")}
            />
            <path
                d="M2 2L12 12M12 2L2 12"
                className={clsx("origin-center transition", !props.open && "scale-90 opacity-0")}
            />
        </svg>
    );
}

function MobileNavigation() {
    return (
        <Popover>
            <Popover.Button
                className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
                aria-label="Toggle Navigation"
            >
                {({ open }) => <MobileNavIcon open={open} />}
            </Popover.Button>
            <Transition.Root>
                <Transition.Child
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="duration-150 ease-in"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Popover.Panel
                        as="div"
                        className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
                    >
                        <MobileNavLink href="#features">Služby</MobileNavLink>
                        <MobileNavLink href="#testimonials">Reference</MobileNavLink>
                        <MobileNavLink href="#pricing">Ceník</MobileNavLink>
                        <MobileNavLink href="#contacts">Kontakt</MobileNavLink>
                        <hr className="m-2 border-slate-300/40" />
                        <MobileNavLink href="https://app.uctio.cz">Přihlášení</MobileNavLink>
                    </Popover.Panel>
                </Transition.Child>
            </Transition.Root>
        </Popover>
    );
}

export function Header() {
    return (
        <header className="py-10">
            <Container>
                <nav className="relative z-50 flex justify-between">
                    <div className="flex items-center md:gap-x-12">
                        <Link href="#" aria-label="Home">
                            <Logo className="h-14 w-auto" />
                        </Link>
                        <div className="hidden md:flex md:gap-x-6">
                            <NavLink href="#features">Služby</NavLink>
                            <NavLink href="#testimonials">Reference</NavLink>
                            <NavLink href="#pricing">Ceník</NavLink>
                            <NavLink href="#contacts">Kontakt</NavLink>
                        </div>
                    </div>
                    <div className="flex items-center gap-x-5 md:gap-x-8">
                        <div className="hidden md:block">
                            <NavLink href="https://app.uctio.cz">Přihlášení</NavLink>
                        </div>
                        <div className="-mr-1 md:hidden">
                            <MobileNavigation />
                        </div>
                    </div>
                </nav>
            </Container>
        </header>
    );
}
